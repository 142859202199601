import React, { useEffect, useContext, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import service from 'undefined-service-web'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import { Grid, TextField, InputAdornment, Button, Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { getHttp, postHttp } from '../../../services/http'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import MaterialTableUC from '../../../components/MaterialTableUC'
import { FileUploader } from 'react-drag-drop-files'
import _, { update } from 'lodash'
const useStyles = makeStyles(theme => ({
  search: {
    '& .MuiInputBase-root,input': {
      height: '30px',
      borderRadius: '20px',
      fontSize: '14px',
    },
    width: '100%',
    maxWidth: '600px',
  },
  FileUploader: {
    '& .dropfile': {
      width: '350px',
      height: '400px',
      minWidth: '150px',
      border: '1px solid ' + theme.palette.custom.main + ' !important',
      borderRadius: '10px',
      padding: '10px',
      cursor: 'pointer',
    },
    '& .dropfile.error': {
      border: '1px solid red !important',
      '& .dropfile-body': {
        border: '1px dashed red !important',
      },
    },
    '& .dropfile-body': {
      border: '1px dashed  ' + theme.palette.custom.main + ' ',
      width: '100%',
      height: '100%',
      borderRadius: '10px',
    },
    '& .dropfile-body.image': {
      border: 'none',
    },
  },
}))
const Index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const [data, setdata] = React.useState({
    image: {
      invalid: false,
      text: '',
      msg: '',
    },
  })
  const [preview, setpreview] = useState([])

  const broadcast = async () => {
    if (!service.isNullOrEmpty(data.image.text)) {
      dispatch(fetchStart())
      console.log('broadcast')
      let _tbbroadcast = await service.postHttp('/broadcast/send', dispatch)
      if (_tbbroadcast.status) {
        if (_tbbroadcast.data.status) {
          setAlertDialog({
            open: true,
            type: 'alert',
            msg: 'broadcast สำเร็จ',
            Close: even => {
              even()
            },
          })
        } else {
          setAlertDialog({
            open: true,
            type: 'alert',
            msg: 'broadcast ไม่สำเร็จ',
            Close: even => {
              even()
            },
          })
        }
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'broadcast ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
      dispatch(fetchSuccess())
    } else {
      setAlertDialog({
        open: true,
        type: 'alert',
        msg: 'broadcast ยังไม่ได้กำหนดรูป',
        Close: even => {
          even()
        },
      })
    }
  }
  const gettbbroadcast = async isCurrent => {
    dispatch(fetchStart())
    let _tbbroadcast = await service.getHttp('/broadcast', dispatch)
    if (_tbbroadcast.status) {
      if (_tbbroadcast.data.status) {
        let imagePath = null
        if (!service.isNullOrEmpty(_tbbroadcast.data.tbBroadcast.imagePath)) {
          imagePath =
            process.env.REACT_APP_KEY_URLAPI +
            _tbbroadcast.data.tbBroadcast.imagePath
        }
        if (isCurrent) {
          setdata({
            image: {
              text: imagePath,
            },
          })
        }
      }
    }
    dispatch(fetchSuccess())
  }
  const updatebroadcast = async image => {
    dispatch(fetchStart())
    let _save = await service.postHttp('/broadcast', { image: image })
    gettbbroadcast(true)

    setAlert({ open: true, type: 'success', msg: 'บันทึกข้อมูลสำเร็จ' })
    dispatch(fetchSuccess())
  }
  useEffect(() => {
    let isCurrent = true
    gettbbroadcast(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const PM25UC = ({ name, index }) => {
    return (
      <Box style={{ display: 'flex' }} mb={1.5}>
        <Box className={classes.lbl} style={{ width: '120px' }}>
          {name}
        </Box>
        <Box
          className={classes.lbl}
          style={{
            width: '100px',
            color: service.isNullOrEmpty(preview[index]?.realtime.level)
              ? '#000'
              : preview[index]?.realtime.level,
          }}>
          {service.isNullOrEmpty(preview[index]?.realtime.pm25)
            ? '-'
            : preview[index]?.realtime.pm25}
        </Box>
        <Box
          className={classes.lbl}
          style={{
            // width: '100px',
            color: service.isNullOrEmpty(preview[index]?.daily.level)
              ? '#000'
              : preview[index]?.daily.level,
          }}>
          {service.isNullOrEmpty(preview[index]?.daily.pm25)
            ? '-'
            : preview[index]?.daily.pm25}
        </Box>
      </Box>
    )
  }
  const getpreview = async () => {
    dispatch(fetchStart())
    let _preview = await service.postHttp('/broadcast/getpreview', dispatch)
    if (_preview.status) {
      if (_preview.data.status) {
        setpreview(_preview.data.preview)
      }
    }
    dispatch(fetchSuccess())
  }
  return (
    <PageContainer heading={<IntlMessages id="pages.manageBroadcast" />}>
      <Grid container xs={12} style={{ padding: '0' }}>
        <Grid
          item
          xs={12}
          sm={8}
          style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box
            style={{
              // display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 'calc(100% - 120px)',
              // border: '1px solid',
            }}>
            <Box>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}>
                <Box
                  className={classes.FileUploader}
                  style={{ height: '400px', width: '350px' }}>
                  <FileUploader
                    multple={false}
                    handleChange={e => {
                      if (
                        e.type.toLowerCase().includes('png') ||
                        e.type.toLowerCase().includes('jpeg')
                      ) {
                        service.getBase64(e, result => {
                          setAlertDialog({
                            open: true,
                            type: 'confirm',
                            msg: 'ต้องการเปลี่ยนแปลงข้อมูล ใช่หรือไม่?',
                            Close: even => {
                              even()
                            },
                            Ok: even => {
                              even()
                              updatebroadcast(result)
                            },
                          })
                        })
                      } else {
                        setAlertDialog({
                          open: true,
                          type: 'alert',
                          msg:
                            'ประเภทไฟล์ไม่ถูกต้อง*ไฟล์ที่รองรับ: .JPEG, .PNG',
                          Close: even => {
                            even()
                          },
                        })
                      }
                    }}
                    name="image"
                    type={['JPGE', 'PNG']}>
                    <Box
                      className={
                        'dropfile' + (data.image.invalid ? ' error' : '')
                      }>
                      <Box
                        className={
                          'dropfile-body ' +
                          (service.isNullOrEmpty(data.image.text)
                            ? ''
                            : 'image')
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {service.isNullOrEmpty(data.image.text) ? (
                          <Box>
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Button variant="contained" color="primary">
                                Choose File
                              </Button>
                            </Box>
                            <Box
                              style={{
                                fontSize: '9px',
                                textAlign: 'center',
                                marginTop: '5px',
                                color: data.image.invalid ? 'red' : '#000',
                              }}>
                              Or drag and drop file
                            </Box>
                            {data.image.invalid ? (
                              <Box
                                style={{
                                  color: 'red',
                                  fontSize: '9px',
                                  textAlign: 'center',
                                }}>
                                {data.image.msg}
                              </Box>
                            ) : null}
                          </Box>
                        ) : (
                          <img
                            style={{ height: '100%', objectFit: 'contain' }}
                            src={data.image.text}
                            alt="image"></img>
                        )}
                      </Box>
                    </Box>
                  </FileUploader>
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}>
                <Button variant="contained" color="primary" onClick={broadcast}>
                  Broadcast
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box style={{ height: '400px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl} style={{ width: '120px' }}></Box>
              <Box className={classes.lbl} style={{ width: '100px' }}>
                Realtime
              </Box>
              <Box className={classes.lbl} style={{ width: '100px' }}>
                Daily
              </Box>
            </Box>
            <PM25UC name="เชียงใหม่" index={0} />
            <PM25UC name="น่าน" index={1} />
            <PM25UC name="ขอนแก่น" index={2} />
            <PM25UC name="อุบลราชธานี" index={3} />
            <PM25UC name="แม่ฮ่องสอน" index={4} />
            <PM25UC name="พิษณุโลก" index={5} />
            <PM25UC name="สระบุรี" index={6} />
            <PM25UC name="จันทบุรี" index={7} />
            <PM25UC name="ตาก" index={8} />
            <PM25UC name="ระยอง" index={9} />
            <PM25UC name="กาญจนบุรี" index={10} />
            <PM25UC name="กรุงเทพมหานะคร" index={11} />
            <PM25UC name="สงขลา" index={12} />
            <PM25UC name="ภูเก็ต" index={13} />
          </Box>
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '10px',
            }}>
            <Button variant="contained" color="primary" onClick={getpreview}>
              preview
            </Button>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default Index
