import React from 'react'
import * as fn from '../services/default.service'
import MaterialTable from 'material-table'
import { TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles(theme => ({
  IsPageSizeNull: {
    '& .MuiTableFooter-root .MuiTableCell-footer': {
      border: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      display: 'none',
    },
  },
}))

export const PatchedPagination = props => {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={subprops => {
        const { onPageChange, ...actionsComponentProps } = subprops
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        )
      }}
    />
  )
}
export const MaterialTableUC = ({ columns, list, tableRef, pagesize }) => {
  const classes = useStyles()
  let options = {
    toolbar: false,
    // pageSize: 10,
    // pageSizeOptions: [{ value: _pageSize, label: 'All' }],
  }
  if (pagesize === true) {
    options = {
      toolbar: false,
      pageSize: 10,
      // pageSizeOptions: [{ value: _pageSize, label: 'All' }],
    }
  }
  return (
    <div className={pagesize === true ? '' : classes.IsPageSizeNull}>
      <MaterialTable
        tableRef={tableRef}
        icons={fn.tableIcons}
        columns={columns}
        data={list}
        options={options}
        // onPageChange={() => { }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <h1
                style={{
                  // height: '300px',
                  textAlign: 'center',
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}></h1>
            ),
          },
        }}
        components={{
          Pagination: PatchedPagination,
        }}
      />
    </div>
  )
}

export default MaterialTableUC
