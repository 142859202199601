import { THEME_TYPES } from '../@jumbo/constants/ThemeOptions'

export const semiDarkTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#3f51b5',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
}
export const darkTheme = {
  palette: {
    type: THEME_TYPES.DARK,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#3f51b5',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#3f51b5',
      menuHoverBgColor: 'rgb(0, 0, 0, 0.8)',
      menuActiveBgColor: 'rgb(0, 0, 0, 0.5)',
    },
    common: {
      dark: '#fff',
    },
    background: {
      paper: '#121212',
      default: '#2e2e2e',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(255, 255, 255, 0.06)',
      dark: 'rgba(255, 255, 255, 0.12)',
    },
    lightBtn: {
      bgColor: '#535151',
      textColor: 'rgba(255, 255, 255, 0.38)',
    },
    popupColor: {
      main: '#363636',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#535050',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#2e2e2e',
      },
    },
  },
}
export const lightTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },

    MuiButton: {
      containedPrimary: {
        color: '#FFF',
        backgroundColor: '#9E76B4',
        height: '30px',
        '&:hover': {
          opacity: '0.8',
          backgroundColor: '#9E76B4',
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: '#9E76B4',
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: '#9E76B4',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9E76B4',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#ebebeb',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9E76B4',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#9E76B4 !important',
        '&.Mui-checked': {
          color: '#9E76B4 !important',
        },
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTable: {
      root: {
        '& .MuiTableHead-root .MuiTableCell-root': {
          backgroundColor: '#e5e5e5',
          zIndex: '6',
        },

        '& tbody.MuiTableBody-root,.MuiTableHead-root': {
          // border: "1px solid rgba(224, 224, 224, 1)"
        },
        // '& .MuiTableFooter-root .MuiTableCell-footer': {
        //   border: 'none',
        // },
        '& tbody.MuiTableBody-root,td,th': {
          border: '1px solid #ebebeb',
        },
        '& .MuiTablePagination-selectRoot': {
          display: 'none',
        },
        '& .MuiTableBody-root,.MuiTableHead-root': {
          '& .MuiTableRow-root td,th,tr': {
            padding: '0 10px',
            height: '30px !important',
          },
        },
        '& .MuiSvgIcon-root': {
          fontSize: '14px',
        },
        // '& .MuiTableRow-footer': {
        //   display: 'none',
        // },
        '& .MuiTableRow-root:not(:has(> *))': {
          display: 'none',
        },
        '& .MuiTableCell-root.MuiTableCell-body': {
          color: '#000',
        },
        '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft': {
          color: '#FFF',
        },
      },
    },
  },
}
