import React, { cloneElement, isValidElement, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import {
  List,
  // , Box
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext'
import _ from 'lodash'
const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '10px 10px 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px 10px 10px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderLeft: '10px solid transparent',
    // color: props => props.sidebarTheme.textColor,
    color: '#000',
    '&:hover, &:focus': {
      // color: props => props.sidebarTheme.textDarkColor,
      // backgroundColor: props => props.sidebarTheme.navHoverBgColor,
      color: '#000',
      backgroundColor: '#dfcfe8',
      borderLeft: '10px solid ' + theme.palette.custom.main,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: props => props.sidebarTheme.textDarkColor,
      },
    },
    '&.active': {
      // color: props => props.sidebarTheme.textActiveColor,
      // backgroundColor: props => props.sidebarTheme.navActiveBgColor,
      color: '#000',
      backgroundColor: '#dfcfe8',
      borderLeft: '10px solid ' + theme.palette.custom.main,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        // color: props => props.sidebarTheme.textActiveColor,
        color: '#000',
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          // color: props => props.sidebarTheme.textActiveColor,
          color: '#000',
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
}))

const NavMenuItem = props => {
  const { name, icon, link, childrenlink } = props
  const { sidebarTheme } = useContext(SidebarThemeContext)
  const location = useLocation()
  const classes = useStyles({ sidebarTheme })

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      })
    }

    return null
  }
  let active = false
  _.map([...(location.pathname.split('/') || [])], e => {
    _.map([...(childrenlink || [])], j => {
      if ((j || '').includes(e.toLowerCase()) && e !== '' && j !== '') {
        active = true
      }
    })
  })

  return (
    <List
      component="div"
      className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      <NavLink
        className={
          clsx(classes.navMenuLink, 'Cmt-nav-menu-link') +
          ' ' +
          (active ? 'active' : '')
        }
        to={link}>
        {/* Display an icon if any */}

        {renderIcon()}
        <span className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</span>
      </NavLink>
    </List>
  )
}

export default NavMenuItem
