import React, { useContext } from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import AppContext from '../@jumbo/components/contextProvider/AppContextProvider/AppContext'
function AlertMui(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CustomizedSnackbars = () => {
  const { Alert, setAlert } = useContext(AppContext)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert({ open: false, type: 'success' })
  }

  return (
    <Snackbar
      open={Alert.open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <AlertMui onClose={handleClose} severity={Alert.type}>
        {Alert.msg}
      </AlertMui>
    </Snackbar>
  )
}
export default CustomizedSnackbars
