import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import service from 'undefined-service-web'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import { Grid, Box, FormControl, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FileUploader } from 'react-drag-drop-files'
import { faSlash } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles(theme => ({
  formControl: {
    height: '30px',
    width: '100%',
    '& .MuiInputBase-formControl': {
      height: '30px',
    },
    '& .MuiSelect-select': {
      padding: '0 10px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 5px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(.5)',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px',
      margin: '0',
      color: 'transparent',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#f44336',
    },
    '& input': {
      fontSize: '14px',
      height: '30px',
      padding: '0px 10px',
    },
    '& fieldset': { borderColor: theme.palette.custom.main + ' !important' },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .Mui-disabled fieldset': {
      background: '#ebebeb',
    },
    '& .FileUploader': {
      '& .dropfile': {
        width: '150px',
        height: '100px',
        minWidth: '150px',
        border: '1px solid ' + theme.palette.custom.main + ' !important',
        borderRadius: '10px',
        padding: '10px',
        cursor: 'pointer',
      },
      '& .dropfile.error': {
        border: '1px solid red !important',
        '& .dropfile-body': {
          border: '1px dashed red !important',
        },
      },
      '& .dropfile-body': {
        border: '1px dashed  ' + theme.palette.custom.main + ' ',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
      },
      '& .dropfile-body.image': {
        border: 'none',
      },
    },
  },
  lbl: {
    display: 'flex',
    alignItems: 'center',
    // padding: "0 10px",
    width: 'calc(100% - 400px)',
    minWidth: '130px',
    maxWidth: '130px',
  },
  backButton: {
    border: '1px solid ' + theme.palette.custom.main,
    backgroundColor: '#FFF',
    '& .MuiButton-label': {
      color: theme.palette.custom.main,
    },
    '&:hover': {
      border: '1px solid ' + theme.palette.custom.main,
      backgroundColor: '#FFF',
    },
  },
}))
const Info = () => {
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { Id } = useParams()
  const [data, setdata] = React.useState({
    id: null,
    modefy: false,
    image: {
      invalid: false,
      text: '',
      msg: '',
    },
    title: {
      invalid: false,
      text: '',
      msg: '',
    },
    detail: {
      invalid: false,
      text: '',
      msg: '',
    },
    link1: {
      invalid: false,
      text: '',
      msg: '',
    },
    linkTitle1: {
      invalid: false,
      text: '',
      msg: '',
    },
    link2: {
      invalid: false,
      text: '',
      msg: '',
    },
    linkTitle2: {
      invalid: false,
      text: '',
      msg: '',
    },
    link3: {
      invalid: false,
      text: '',
      msg: '',
    },
    linkTitle3: {
      invalid: false,
      text: '',
      msg: '',
    },
    listno: {
      invalid: false,
      text: '',
      msg: '',
    },
  })

  const onChange = e => {
    let name = e.target.name
    data.modefy = true
    let item = data[name]
    item.text = e.target.value
    item.invalid = false
    setdata({ ...data })
    if (name === 'linkTitle2') {
      if (service.isNullOrEmpty(e.target.value)) {
        let link2 = data.link2
        link2.invalid = false
        setdata({ ...data, link2: link2 })
      }
    }
    if (name === 'link2') {
      let linkTitle2 = data.linkTitle2
      if (
        service.isNullOrEmpty(e.target.value) &&
        service.isNullOrEmpty(linkTitle2.text)
      ) {
        linkTitle2.invalid = false
        setdata({ ...data, linkTitle2: linkTitle2 })
      }
    }
    if (name === 'linkTitle3') {
      if (service.isNullOrEmpty(e.target.value)) {
        let link3 = data.link3
        link3.invalid = false
        setdata({ ...data, link3: link3 })
      }
    }
    if (name === 'link3') {
      let linkTitle3 = data.linkTitle3
      if (
        service.isNullOrEmpty(e.target.value) &&
        service.isNullOrEmpty(linkTitle3.text)
      ) {
        linkTitle3.invalid = false
        setdata({ ...data, linkTitle3: linkTitle3 })
      }
    }
  }

  const gettbNewsById = async (isCurrent, Id) => {
    dispatch(fetchStart())
    let user = await service.getHttp('/carousels/' + Id, dispatch)
    if (user.data.status) {
      let tbNews = user.data.tbNews

      if (isCurrent) {
        setdata({
          ...data,
          modefy: false,
          image: {
            text: process.env.REACT_APP_KEY_URLAPI + tbNews.image,
          },
          title: {
            text: tbNews.title,
          },
          detail: {
            text: tbNews.detail,
          },
          link1: {
            text: tbNews.link1,
          },
          linkTitle1: {
            text: tbNews.linkTitle1,
          },
          link2: {
            text: tbNews.link2,
          },
          linkTitle2: {
            text: tbNews.linkTitle2,
          },
          link3: {
            text: tbNews.link3,
          },
          linkTitle3: {
            text: tbNews.linkTitle3,
          },
          listno: {
            text: tbNews.listno,
          },
        })
      }
    }
    dispatch(fetchSuccess())
  }
  const dosave = async callback => {
    if (valid()) {
      let item = {
        id: data.id,
        image: data.image.text,
        title: data.title.text,
        detail: data.detail.text,
        link1: data.link1.text,
        linkTitle1: data.linkTitle1.text,
        link2: data.link2.text,
        linkTitle2: data.linkTitle2.text,
        link3: data.link3.text,
        linkTitle3: data.linkTitle3.text,
        listno: data.listno.text,
      }
      dispatch(fetchStart())
      let _save = await service.postHttp('/carousels', item)
      if (_save.status) {
        if (_save.data.status) {
          if (callback !== undefined) {
            callback()
          } else {
            if (service.isNullOrEmpty(data.id)) {
              data.id = _save.data.id
              data.showPassword = false
              data.editPassword = false
              data.modefy = false

              setdata({ ...data })
              window.history.replaceState(
                null,
                null,
                '/carousel/' + _save.data.id,
              )
              gettbNewsById(true, _save.data.id)
            } else {
              data.showPassword = false
              data.editPassword = false
              data.modefy = false

              setdata({ ...data })
              gettbNewsById(true, data.id)
            }
            setAlert({ open: true, type: 'success', msg: 'บันทึกข้อมูลสำเร็จ' })
          }
        } else {
          if (_save.data.isDupdate) {
            service.lodash.map([..._save.data.Dupdate], e => {
              if (e === 'listno') {
                let listno = data.listno
                listno.msg = 'ลำดับการแสดงซ้ำกับในระบบกรุณากรอกข้อมูลใหม่'
                listno.invalid = true

                setdata({ ...data, listno: listno })
              }
              if (e === 'title') {
                let title = data.title
                title.msg = 'หัวข้อซ้ำกับในระบบกรุณากรอกข้อมูลใหม่'
                title.invalid = true

                setdata({ ...data, title: title })
              }
            })
          } else {
            setAlertDialog({
              open: true,
              type: 'alert',
              msg: 'บันทึกข้อมูลสำเร็จ',
              Close: even => {
                even()
              },
            })
          }
        }
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'ลบข้อมูล ไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
      dispatch(fetchSuccess())
    }
  }
  const valid = () => {
    let valid = !0
    if (service.isNullOrEmpty(data.image.text)) {
      let image = data.image
      image.msg = 'กรุณาระบุรูป'
      image.invalid = true
      valid = !1
      setdata({ ...data, image: image })
    }
    if (service.isNullOrEmpty(data.title.text)) {
      let title = data.title
      title.msg = 'กรุณาระบุหัวข้อ'
      title.invalid = true
      valid = !1
      setdata({ ...data, title: title })
    }
    if (service.isNullOrEmpty(data.linkTitle1.text)) {
      let linkTitle1 = data.linkTitle1
      linkTitle1.msg = 'กรุณาระบุชื่อ link 1'
      linkTitle1.invalid = true
      valid = !1
      setdata({ ...data, linkTitle1: linkTitle1 })
    }
    if (service.isNullOrEmpty(data.link1.text)) {
      let link1 = data.link1
      link1.msg = 'กรุณาระบุ link 1'
      link1.invalid = true
      valid = !1
      setdata({ ...data, link1: link1 })
    }

    if (
      !service.isNullOrEmpty(data.linkTitle2.text) ||
      !service.isNullOrEmpty(data.link2.text)
    ) {
      if (service.isNullOrEmpty(data.linkTitle2.text)) {
        let linkTitle2 = data.linkTitle2
        linkTitle2.msg = 'กรุณาระบุชื่อ link 2'
        linkTitle2.invalid = true
        valid = !1
        setdata({ ...data, linkTitle2: linkTitle2 })
      }
      if (service.isNullOrEmpty(data.link2.text)) {
        let link2 = data.link2
        link2.msg = 'กรุณาระบุ link 2'
        link2.invalid = true
        valid = !1
        setdata({ ...data, link2: link2 })
      }
    } else {
      let linkTitle2 = data.linkTitle2
      let link2 = data.link2
      linkTitle2.invalid = false
      link2.invalid = false
      setdata({ ...data, link2: link2, linkTitle2: linkTitle2 })
    }
    if (
      !service.isNullOrEmpty(data.linkTitle3.text) ||
      !service.isNullOrEmpty(data.link3.text)
    ) {
      if (service.isNullOrEmpty(data.linkTitle3.text)) {
        let linkTitle3 = data.linkTitle3
        linkTitle3.msg = 'กรุณาระบุชื่อ link 3'
        linkTitle3.invalid = true
        valid = !1
        setdata({ ...data, linkTitle3: linkTitle3 })
      }
      if (service.isNullOrEmpty(data.link3.text)) {
        let link3 = data.link3
        link3.msg = 'กรุณาระบุ link 3'
        link3.invalid = true
        valid = !1
        setdata({ ...data, link3: link3 })
      }
    } else {
      let linkTitle3 = data.linkTitle3
      let link3 = data.link3
      linkTitle3.invalid = false
      link3.invalid = false
      setdata({ ...data, link3: link3, linkTitle3: linkTitle3 })
    }

    if (!service.isNullOrEmpty(data.link1.text)) {
      if (!service.UrlDetect(data.link1.text)) {
        let link1 = data.link1
        link1.msg = 'link 1 ไม่ถูกต้อง'
        link1.invalid = true
        valid = !1
        setdata({ ...data, link1: link1 })
      }
    }
    if (!service.isNullOrEmpty(data.link2.text)) {
      if (!service.UrlDetect(data.link2.text)) {
        let link2 = data.link2
        link2.msg = 'link 2 ไม่ถูกต้อง'
        link2.invalid = true
        valid = !1
        setdata({ ...data, link2: link2 })
      }
    }
    if (!service.isNullOrEmpty(data.link3.text)) {
      if (!service.UrlDetect(data.link3.text)) {
        let link3 = data.link3
        link3.msg = 'link 3 ไม่ถูกต้อง'
        link3.invalid = true
        valid = !1
        setdata({ ...data, link3: link3 })
      }
    }

    if (service.isNullOrEmpty(data.listno.text)) {
      let listno = data.listno
      listno.msg = 'กรุณาระบุลำดับการแสดง'
      listno.invalid = true
      valid = !1
      setdata({ ...data, listno: listno })
    }
    return valid
  }

  useEffect(() => {
    let isCurrent = true
    if (!service.isNullOrEmpty(Id)) {
      data.id = Id

      setdata({ ...data })
      gettbNewsById(isCurrent, Id)
    }
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const breadcrumbs = [
    {
      label: (
        <Box style={{ display: 'flex' }}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault()
              if (data.modefy) {
                setAlertDialog({
                  open: true,
                  type: 'confirm',
                  msg: 'มีการแก้ไขต้องการบันทึก ใช่หรือไม่?',
                  Close: e => {
                    e()
                    history.push('/carousel')
                  },
                  Ok: e => {
                    e()
                    dosave(() => {
                      history.push('/carousel')
                    })
                  },
                })
              } else {
                history.push('/carousel')
              }
            }}>
            กลับ
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 10px' }}
            onClick={e => {
              e.preventDefault()
              dosave()
            }}>
            บันทึก
          </Button>
        </Box>
      ),
    },
  ]

  return (
    <PageContainer
      heading={<IntlMessages id="pages.manageCarousel" />}
      breadcrumbs={breadcrumbs}>
      <GridContainer style={{ padding: '0 15px 0 65px' }}>
        {/* <Grid item xs={12} style={{ padding: "0" }}> */}
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                รูป <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ height: '100px', width: '100%' }}>
                <Box
                  className="FileUploader"
                  style={{ height: '100px', display: 'flex', width: '100%' }}>
                  <FileUploader
                    multple={false}
                    maxSize={4}
                    handleChange={(e, v) => {
                      if (
                        e.type.toLowerCase().includes('png') ||
                        e.type.toLowerCase().includes('jpeg')
                      ) {
                        const _URL = window.URL || window.webkitURL
                        const img = new Image()
                        const objectUrl = _URL.createObjectURL(e)
                        const size = e.size / 1024 / 1024
                        img.onload = function() {
                          if (
                            this.width > 1024
                            //  ||
                            // this.height > 1024 ||
                            // this.width !== this.height
                          ) {
                            setAlertDialog({
                              open: true,
                              type: 'alert',
                              msg:
                                'ความกว้าง : ไม่เกิน 1024px (ทรงสีเหลียมผืนผ้า)',
                              Close: even => {
                                even()
                              },
                            })
                          } else {
                            if (size > 4) {
                              setAlertDialog({
                                open: true,
                                type: 'alert',
                                msg: 'ขนาดไฟล์: สูงสุด 4 MB',
                                Close: even => {
                                  even()
                                },
                              })
                            } else {
                              service.getBase64(e, result => {
                                setdata({
                                  ...data,
                                  image: {
                                    invalid: false,
                                    text: result,
                                    msg: '',
                                  },
                                })
                              })
                            }
                          }

                          _URL.revokeObjectURL(objectUrl)
                        }
                        img.src = objectUrl
                      } else {
                        setAlertDialog({
                          open: true,
                          type: 'alert',
                          msg:
                            'ประเภทไฟล์ไม่ถูกต้อง*ไฟล์ที่รองรับ: .JPEG, .PNG',
                          Close: even => {
                            even()
                          },
                        })
                      }
                    }}
                    fileOrFiles={data.image.text}
                    name="image"
                    type={['JPGE', 'PNG']}>
                    <Box
                      className={
                        'dropfile' + (data.image.invalid ? ' error' : '')
                      }>
                      <Box
                        className={
                          'dropfile-body ' +
                          (service.isNullOrEmpty(data.image.text)
                            ? ''
                            : 'image')
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {service.isNullOrEmpty(data.image.text) ? (
                          <Box>
                            <Box
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Button variant="contained" color="primary">
                                Choose File
                              </Button>
                            </Box>
                            <Box
                              style={{
                                fontSize: '9px',
                                textAlign: 'center',
                                marginTop: '5px',
                                color: data.image.invalid ? 'red' : '#000',
                              }}>
                              Or drag and drop file
                            </Box>
                            {data.image.invalid ? (
                              <Box
                                style={{
                                  color: 'red',
                                  fontSize: '9px',
                                  textAlign: 'center',
                                }}>
                                {data.image.msg}
                              </Box>
                            ) : null}
                          </Box>
                        ) : (
                          <img
                            style={{ height: '100%', objectFit: 'contain' }}
                            src={data.image.text}
                            alt="img-by-carousel"></img>
                        )}
                      </Box>
                    </Box>
                  </FileUploader>
                  <Box
                    style={{
                      padding: '10px',
                      width: '100%',
                      fontSize: '10px',
                      color: '#dfcfe8',
                    }}>
                    <Box>ขนาดไฟล์: สูงสุด 4 MB</Box>
                    <Box>ความกว้าง : ไม่เกิน 1024px (ทรงสีเหลียมผืนผ้า)</Box>
                    <Box>ไฟล์ที่รองรับ: .JPEG, .PNG</Box>
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                หัวข้อ <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-title"
                  name="title"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.title.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="หัวข้อ"
                  error={data.title.invalid}
                  helperText={data.title.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>รายละเอียด</Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-detail"
                  name="detail"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.detail.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="รายละเอียด"
                  error={data.detail.invalid}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0', display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                Link 1 <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-linkTitle1"
                  name="linkTitle1"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.linkTitle1.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 40,
                  }}
                  placeholder="ชื่อ Link 1"
                  error={data.linkTitle1.invalid}
                  helperText={data.linkTitle1.msg}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-link1"
                  name="link1"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.link1.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="link 1"
                  error={data.link1.invalid}
                  helperText={data.link1.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0', display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>Link 2</Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-linkTitle2"
                  name="linkTitle2"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.linkTitle2.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 40,
                  }}
                  placeholder="ชื่อ Link 2"
                  error={data.linkTitle2.invalid}
                  helperText={data.linkTitle2.msg}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-link2"
                  name="link2"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.link2.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="link2"
                  error={data.link2.invalid}
                  helperText={data.link2.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0', display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>Link 3</Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-linkTitle3"
                  name="linkTitle3"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.linkTitle3.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 40,
                  }}
                  placeholder="ชื่อ Link 3"
                  error={data.linkTitle3.invalid}
                  helperText={data.linkTitle3.msg}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-link3"
                  name="link3"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.link3.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="link3"
                  error={data.link3.invalid}
                  helperText={data.link3.msg}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '0', display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                ลำดับการแสดง <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="input-listno"
                  name="listno"
                  disabled={false}
                  type={'text'}
                  onChange={onChange}
                  value={data.listno.text}
                  variant="outlined"
                  inputProps={{
                    maxLength: 250,
                    style: { textAlign: 'right' },
                  }}
                  placeholder="ลำดับการแสดง"
                  error={data.listno.invalid}
                  helperText={data.listno.msg}
                  onKeyDown={e => {
                    if (
                      e.key != 'Backspace' &&
                      e.key != 'Delete' &&
                      e.key != 'ArrowRight' &&
                      e.key != 'ArrowLeft'
                    )
                      if (!'0123456789'.includes(e.key)) {
                        e.preventDefault()
                      }
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </PageContainer>
  )
}

export default Info
