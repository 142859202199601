import React, { useState, useContext } from 'react'
import {
  useDispatch,
  // , useSelector
} from 'react-redux'
// import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { alpha } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import Typography from '@material-ui/core/Typography'
import CmtImage from '../../../../@coremat/CmtImage'
import { Box } from '@material-ui/core'
import IntlMessages from '../../../utils/IntlMessages'
import ContentLoader from '../../ContentLoader'
import { CurrentAuthMethod } from '../../../constants/AppConstants'
import AuthWrapper from './AuthWrapper'
import { NavLink } from 'react-router-dom'
import * as fn from '../../../../services/default.service'
import { postHttp } from '../../../../services/http'
import { fetchStart, fetchSuccess, fetchError } from '../../../../redux/actions'
import AppContext from '../../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: '0 50px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      // padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 25,
    marginTop: 60,
    color: theme.palette.text.primary,
    fontWeight: 'bold !important',
    fontSize: '18px !important',
    '&.MuiTypography-root': {
      color: '#000',
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(15px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -7px) scale(.75)',
    },
    '& input': {
      padding: '0 15px',
      height: '40px',
    },
    '& .MuiFormLabel-root': { fontSize: '14px' },
    marginBottom: '0px',
    marginTop: '0px',
  },
  textFieldRootError: {
    '& fieldset': {
      borderColor: 'red !important',
    },
    '& .MuiInputLabel-root': {
      color: 'red  !important',
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}))

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({
  method = CurrentAuthMethod,
  variant = 'default',
  wrapperVariant = 'default',
}) => {
  // const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const {
    setAlertDialog,
    // , setAlert
  } = useContext(AppContext)
  // const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState({
    text: '',
    msg: '',
    invalid: false,
  })
  const dispatch = useDispatch()
  const classes = useStyles({ variant })
  // const history = useHistory();

  const onSubmit = async e => {
    let _email = email
    if (fn.IsNullOrEmpty(_email.text)) {
      setEmail({
        text: _email.text,
        msg: 'กรุณาระบุอีเมล',
        invalid: true,
      })
    } else if (!fn.IsNullOrEmpty(_email.text)) {
      if (!fn.validateEmail(_email.text)) {
        setEmail({
          text: _email.text,
          msg: 'อีเมลไม่ถูกต้อง',
          invalid: true,
        })
      } else {
        dispatch(fetchStart())

        let _forgotpassword = await postHttp('/users/forgotpassword', {
          email: _email.text,
        })
        if (_forgotpassword.status) {
          if (_forgotpassword.data.status) {
            setAlertDialog({
              open: true,
              type: 'alert',
              msg:
                'ระบบส่ง Link Reset Password ไปยัง Email ของท่านเรียบร้อยแล้ว',
              Close: even => {
                even()
              },
            })
          } else {
            if (_forgotpassword.data.isEmail === false) {
              setEmail({
                text: _email.text,
                msg: 'ไม่พบ Email ในระบบ',
                invalid: true,
              })
            } else if (_forgotpassword.data.isSend === false) {
              dispatch(fetchError('error 400 '))
            } else {
              dispatch(fetchError('error 400 '))
            }
          }
        } else {
          dispatch(fetchError('error 400 '))
        }
        dispatch(fetchSuccess())
      }
      // console.log(email)
    }
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <Box
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: '26px',
          }}>
          <img
            src={'/images/logomini.png'}
            style={{ height: '50px' }}
            alt="logo-econ"
          />
        </Box>
        <Box component="div" variant="h1" className={classes.titleRoot}>
          FORGOT PASSWORD
        </Box>
        <Box style={{ fontSize: '14px', marginBottom: '25px', color: '#000' }}>
          Enter your email adress below to reset password
        </Box>

        <form>
          <div className={'mb-5'}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event =>
                setEmail({
                  text: event.target.value,
                  invalid: false,
                })
              }
              value={email.text}
              margin="normal"
              variant="outlined"
              className={
                classes.textFieldRoot +
                ' ' +
                (email.invalid ? classes.textFieldRootError : '')
              }
            />
            <Box
              style={{
                padding: '0 0 0 10px',
                height: '18px',
                fontSize: '12px',
                color: email.invalid ? 'red' : 'transparent',
                position: 'absolute',
              }}>
              {email.msg}
            </Box>
          </div>
          <Box display="flex" alignItems="center" justifyContent="end" mb={5}>
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink
                to="/signin"
                style={{ color: '#9E76B4', fontSize: '14px' }}>
                <IntlMessages id="appModule.goLogin" />
              </NavLink>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              style={{
                width: '100%',
                height: '50px',
                fontSize: '14px',
              }}>
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </Box>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  )
}

export default ForgotPassword
