import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions'
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
} from '../../../redux/actions/Auth'
import React from 'react'
import axios from './config'

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart())

      setTimeout(() => {
        dispatch(fetchSuccess())
        const user = { name: name, email: email, password: password }
        localStorage.setItem('user', JSON.stringify(user))
        dispatch(setAuthUser(user))
      }, 300)
    }
  },

  onLogin: async ({ userName, password }) => {
    const login = async dispatch => {
      try {
        dispatch(fetchStart())
        let status = await axios
          .post('users/login', {
            userName: userName,
            password: password,
          })
          .then(async ({ data }) => {
            if (data.status) {
              localStorage.setItem('token', data.access_token)
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + data.access_token
              dispatch(fetchSuccess())
              dispatch(setAuthUser(data.access_token))
              return true
            } else {
              dispatch(fetchError(data.error))
              return false
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message))
            return false
          })
        return status
      } catch (error) {
        dispatch(fetchError(error.message))
        return false
      }
    }
    return await login()
    // return () => async dispatch => {

    // };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart())

      setTimeout(() => {
        dispatch(fetchSuccess())
        localStorage.removeItem('user')
        dispatch(setAuthUser(null))
      }, 300)
    }
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart())
      dispatch(updateLoadUser(loaded))

      setTimeout(() => {
        dispatch(fetchSuccess())
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))))
      }, 300)
    }
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart())

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true))
        dispatch(fetchSuccess())
      }, 300)
    }
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>
  },
}

export default BasicAuth
