import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Switch } from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import configureStore, { history } from './redux/store'
import AppWrapper from './@jumbo/components/AppWrapper'
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider'
import Routes from './routes'
import AlertDialogSlide from './components/Dialog'
import CustomizedSnackbars from './components/Alert'
export const store = configureStore()

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContextProvider>
        <AppWrapper>
          <AlertDialogSlide />
          <CustomizedSnackbars />
          <Switch>
            <Routes />
          </Switch>
        </AppWrapper>
      </AppContextProvider>
    </ConnectedRouter>
  </Provider>
)

export default App
