import React from 'react'

import { Redirect, Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Error404 from './Pages/404'
import Login from './Auth/Login'
import Register from './Auth/Register'
import ForgotPasswordPage from './Auth/ForgotPassword'
import Resetpassword from './Auth/resetpassword'

import carousel from './Pages/carousel'
import carouselInfo from './Pages/carousel/info'
import broadcast from './Pages/broadcast'
import profile from './Pages/profile'

import { setAuthUser } from '../redux/actions/Auth'
import { getAuthUser } from '../services/http'

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth)
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const Routes = () => {
  const dispatch = useDispatch()

  let { authUser } = useSelector(({ auth }) => auth)
  const location = useLocation()
  if (authUser === null) {
    if (getAuthUser() !== null) {
      dispatch(setAuthUser(getAuthUser()))
    }

    if (location.pathname === '' || location.pathname === '/') {
      return <Redirect to={'/carousel'} />
    } else if (authUser && location.pathname === '/signin') {
      return <Redirect to={'/carousel'} />
    }
    // console.clear()
  } else {
    if (location.pathname === '' || location.pathname === '/') {
      return <Redirect to={'/carousel'} />
    } else if (authUser && location.pathname === '/signin') {
      return <Redirect to={'/carousel'} />
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/carousel" component={carousel} />
        <RestrictedRoute path="/carouselInfo/:Id?" component={carouselInfo} />
        <RestrictedRoute path="/broadcast" component={broadcast} />
        <RestrictedRoute path="/profile" component={profile} />

        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/resetpassword/:Id" component={Resetpassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  )
}

export default Routes
