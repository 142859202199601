import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainer from '../../../@jumbo/components/GridContainer'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer'
import IntlMessages from '../../../@jumbo/utils/IntlMessages'
import {
  Grid,
  Box,
  FormControl,
  TextField,
  OutlinedInput,
  // , FormHelperText
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useParams } from 'react-router-dom'
import * as fn from '../../../services/default.service'
import { getHttp, postHttp } from '../../../services/http'
import { fetchStart, fetchSuccess } from '../../../redux/actions'
import Button from '@material-ui/core/Button'
import AppContext from '../../../@jumbo/components/contextProvider/AppContextProvider/AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  formControl: {
    height: '30px',
    width: '400px',
    '& .MuiInputBase-formControl': {
      height: '30px',
    },
    '& .MuiSelect-select': {
      padding: '0 10px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 5px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, -5px) scale(.5)',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px',
      margin: '0',
      color: 'transparent',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#f44336',
    },
    '& input': {
      fontSize: '14px',
      height: '30px',
      padding: '0px 10px',
    },
    '& fieldset': { borderColor: theme.palette.custom.main + ' !important' },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336 !important',
    },
    '& .Mui-disabled fieldset': {
      background: '#ebebeb',
    },
  },
  lbl: {
    display: 'flex',
    alignItems: 'center',
    // padding: "0 10px",
    width: 'calc(100% - 400px)',
    minWidth: '120px',
    maxWidth: '150px',
  },
  backButton: {
    border: '1px solid #9E76B4',
    backgroundColor: '#FFF',
    '& .MuiButton-label': {
      color: '#9E76B4',
    },
    '&:hover': {
      border: '1px solid #9E76B4',
      backgroundColor: '#FFF',
    },
  },
}))
const Info = () => {
  const { setAlertDialog, setAlert } = useContext(AppContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { Id } = useParams()
  const [data, setdata] = React.useState({
    id: null,
    modefy: false,
    editPassword: false,
    userName: {
      invalid: false,
      text: '',
      msg: '',
    },
    password: {
      invalid: false,
      text: '',
      msg: '',
    },
    confirmPassword: {
      invalid: false,
      text: '',
      msg: '',
    },
    showPassword: false,
  })

  const onChange = e => {
    let name = e.target.name
    data.modefy = true
    if ('password,confirmPassword'.includes(name)) {
      let password = data['password']
      password.invalid = false
      let confirmPassword = data['confirmPassword']
      confirmPassword.invalid = false
      if (name === 'password') {
        password.text = e.target.value
      } else {
        confirmPassword.text = e.target.value
      }
      if (
        !fn.IsNullOrEmpty(password.text) &&
        !fn.IsNullOrEmpty(!confirmPassword.text)
      ) {
        if (data.confirmPassword.text !== data.password.text) {
          confirmPassword.msg = 'Confirm ไม่ถูกต้อง'
          confirmPassword.invalid = true
          password.msg = ''
          password.invalid = true
          setdata({
            ...data,
            confirmPassword: confirmPassword,
            password: password,
          })
        }
      }
      setdata({ ...data })
    } else {
      let item = data[name]
      item.text = e.target.value
      item.invalid = false
      setdata({ ...data })
    }
  }
  const handleClickShowPassword = () => {
    setdata({ ...data, showPassword: !data.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const getUser = async (isCurrent, Id) => {
    dispatch(fetchStart())
    let user = await getHttp('/users', dispatch)
    if (user.data.status) {
      let tbUser = user.data.tbUser
      if (isCurrent) {
        setdata({
          ...data,
          modefy: false,
          editPassword: false,
          id: tbUser.id,
          userName: {
            text: tbUser.userName,
          },
          password: {
            text: tbUser.password,
          },
          confirmPassword: {
            text: tbUser.password,
          },
          showPassword: false,
        })
      }
    }
    dispatch(fetchSuccess())
  }
  const dosave = async callback => {
    if (valid()) {
      let item = {
        id: data.id,
        userName: data.userName.text,
        password: fn.IsNullOrEmpty(data.id)
          ? data.password.text
          : data.editPassword
          ? data.password.text
          : null,
        editPassword: fn.IsNullOrEmpty(data.id) ? true : data.editPassword,
      }
      dispatch(fetchStart())
      let _save = await postHttp('/users', item)
      if (_save.status) {
        if (_save.data.status) {
          if (callback !== undefined) {
            callback()
          } else {
            getUser(true)
            setAlert({ open: true, type: 'success', msg: 'บันทึกข้อมูลสำเร็จ' })
          }
        } else {
          getUser(true)
          setAlertDialog({
            open: true,
            type: 'alert',
            msg: 'บันทึกข้อมูลไม่สำเร็จ',
            Close: even => {
              even()
            },
          })
        }
      } else {
        setAlertDialog({
          open: true,
          type: 'alert',
          msg: 'บันทึกข้อมูลไม่สำเร็จ',
          Close: even => {
            even()
          },
        })
      }
      dispatch(fetchSuccess())
    }
  }
  const valid = () => {
    let valid = !0
    if (fn.IsNullOrEmpty(data.userName.text)) {
      let userName = data.userName
      userName.msg = 'กรุณาระบุ UserName'
      userName.invalid = true
      valid = !1
      setdata({ ...data, userName: userName })
    }

    const checkPassword = () => {
      if (fn.IsNullOrEmpty(data.password.text)) {
        let password = data.password
        password.msg = 'กรุณาระบุ Password'
        password.invalid = true
        valid = !1
        setdata({ ...data, password: password })
      }
      if (fn.IsNullOrEmpty(data.confirmPassword.text)) {
        let confirmPassword = data.confirmPassword
        confirmPassword.msg = 'กรุณาระบุ Confirm Password'
        confirmPassword.invalid = true
        valid = !1
        setdata({ ...data, confirmPassword: confirmPassword })
      }
      if (
        !fn.IsNullOrEmpty(data.password.text) &&
        !fn.IsNullOrEmpty(!data.confirmPassword.text)
      ) {
        if (data.confirmPassword.text !== data.password.text) {
          let confirmPassword = data.confirmPassword
          let password = data.password
          confirmPassword.msg = 'Confirm ไม่ถูกต้อง'
          confirmPassword.invalid = true
          password.msg = ''
          password.invalid = true
          valid = !1
          setdata({
            ...data,
            confirmPassword: confirmPassword,
            password: password,
          })
        }
      }

      if (!fn.IsNullOrEmpty(data.password.text)) {
        if (data.password.text.length < 8) {
          valid = !1
        }
        if (!data.password.text.match(/[A-Z]/)) {
          valid = !1
        }
        if (!data.password.text.match(/[a-z]/)) {
          valid = !1
        }
        if (
          !data.password.text.match(/[\d`~!@#$%\\^&*()+=|;:'",.<>\\/?\\\\-]/)
        ) {
          valid = !1
        }
      }
    }
    if (fn.IsNullOrEmpty(data.id)) {
      checkPassword()
    } else {
      if (data.editPassword) {
        checkPassword()
      }
    }
    return valid
  }
  useEffect(() => {
    let isCurrent = true
    getUser(isCurrent)
    return () => {
      isCurrent = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const breadcrumbs = [
    {
      label: (
        <Box style={{ display: 'flex' }}>
          <Button
            className={classes.backButton}
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault()
              if (data.modefy) {
                setAlertDialog({
                  open: true,
                  type: 'confirm',
                  msg: 'มีการแก้ไขต้องการบันทึก ใช่หรือไม่?',
                  Close: e => {
                    e()
                    history.goBack()
                  },
                  Ok: e => {
                    e()
                    dosave(() => {
                      history.goBack()
                    })
                  },
                })
              } else {
                history.goBack()
              }
            }}>
            กลับ
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 10px' }}
            onClick={e => {
              e.preventDefault()
              dosave()
            }}>
            บันทึก
          </Button>
        </Box>
      ),
    },
  ]

  let colour1 = 'red',
    colour2 = 'red',
    colour3 = 'red',
    colour4 = 'red',
    colour5 = 'red'
  if (
    (data.editPassword || fn.IsNullOrEmpty(data.id)) &&
    !fn.IsNullOrEmpty(data.password.text)
  ) {
    if (data.password.text.length >= 8) {
      colour1 = 'green'
    }
    if (data.password.text.match(/[A-Z]/)) {
      colour2 = 'green'
    }
    if (data.password.text.match(/[a-z]/)) {
      colour3 = 'green'
    }
    if (data.password.text.match(/[\d`~!@#$%\\^&*()+=|;:'",.<>\\/?\\\\-]/)) {
      colour4 = 'green'
    }
    if (
      data.password.text === data.confirmPassword.text &&
      data.password.text !== ''
    ) {
      colour5 = 'green'
    }
  }

  return (
    <PageContainer
      heading={<IntlMessages id="pages.profile" />}
      breadcrumbs={breadcrumbs}>
      <GridContainer style={{ padding: '0 15px 0 65px' }}>
        {/* <Grid item xs={12} style={{ padding: "0" }}> */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Box style={{ display: 'flex' }}>
            <Box className={classes.lbl}>
              Username <span style={{ color: 'red' }}>*</span>
            </Box>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="input-userName"
                name="userName"
                type="text"
                onChange={onChange}
                value={data.userName.text}
                variant="outlined"
                inputProps={{
                  maxLength: 250,
                }}
                placeholder="Username"
                error={data.userName.invalid}
                helperText={data.userName.msg}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              data.editPassword = !data.editPassword
              data.password.text = ''
              data.confirmPassword.text = ''
              setdata({ ...data })
            }}>
            เปลี่ยน Password
          </Button>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                Password <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <OutlinedInput
                  id="input-password"
                  name="password"
                  disabled={data.editPassword ? false : true}
                  type={data.showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={data.password.text}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {data.showPassword ? (
                          <VisibilityOff
                            style={{ color: 'c9c9c9', width: '15px' }}
                          />
                        ) : (
                          <Visibility
                            style={{ color: 'c9c9c9', width: '15px' }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{
                    maxLength: 250,
                  }}
                  placeholder="Password"
                  error={data.password.invalid}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {data.editPassword ? (
          <Grid item xs={12} style={{ padding: '0' }}>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
              <Box style={{ display: 'flex' }}>
                <Box className={classes.lbl}>
                  {/* Password <span style={{ color: 'red' }}>*</span> */}
                </Box>
                <Box>
                  <Box style={{ color: colour1 }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: colour1 }}
                    />{' '}
                    At least 8 characters
                  </Box>
                  <Box style={{ color: colour2 }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: colour2 }}
                    />{' '}
                    At least 1 uppercase letter
                  </Box>
                  <Box style={{ color: colour3 }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: colour3 }}
                    />{' '}
                    At least 1 lowercase letter
                  </Box>
                  <Box style={{ color: colour4 }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: colour4 }}
                    />{' '}
                    At least 1 number or special character
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} style={{ padding: '0' }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
            <Box style={{ display: 'flex' }}>
              <Box className={classes.lbl}>
                Confirm Password <span style={{ color: 'red' }}>*</span>
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <OutlinedInput
                  id="input-password"
                  name="confirmPassword"
                  disabled={data.editPassword ? false : true}
                  type={data.showPassword ? 'text' : 'password'}
                  onChange={onChange}
                  value={data.confirmPassword.text}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {data.showPassword ? (
                          <VisibilityOff
                            style={{ color: 'c9c9c9', width: '15px' }}
                          />
                        ) : (
                          <Visibility
                            style={{ color: 'c9c9c9', width: '15px' }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Confirm Password"
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={data.password.invalid}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {data.editPassword ? (
          <Grid item xs={12} style={{ padding: '0' }}>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '0 20px 0 0', marginBottom: '15px' }}>
              <Box style={{ display: 'flex' }}>
                <Box className={classes.lbl}>
                  {/* Password <span style={{ color: 'red' }}>*</span> */}
                </Box>
                <Box>
                  <Box style={{ color: colour5 }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: colour5 }}
                    />{' '}
                    Password == Confirm Password
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </GridContainer>
    </PageContainer>
  )
}

export default Info
