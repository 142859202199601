import React, { useContext } from 'react'
import { MenuItem, MenuList, Paper, Popover, Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch } from 'react-redux'
import { AuhMethods } from '../../../../services/auth'
import { CurrentAuthMethod } from '../../../constants/AppConstants'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext'
import SidebarToggleHandler from '../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 30px',
    marginBottom: '20px',
    '& .Cmt-toggle-menu': {
      padding: 0,
    },
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}))

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext)
  const classes = useStyles({ sidebarTheme })
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onLogoutClick = () => {
    handlePopoverClose()
    dispatch(AuhMethods[CurrentAuthMethod].onLogout())
  }

  return (
    <div className={classes.root}>
      <Box>
        <Box style={{ display: 'flex' }}>
          <Box
            style={{
              width: 'calc(100% - 30px)',
              display: 'flex',
              alignItems: 'center',
            }}>
            <img
              alt={'logo'}
              src={'/images/logomain.png'}
              style={{
                width: '140px',
                objectFit: 'scale-down',
                alignItems: 'center',
              }}
            />
          </Box>
          <SidebarToggleHandler
            // edge="start"
            color="primary"
            aria-label="menu"
          />
        </Box>
        <Box></Box>
      </Box>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <PowerSettingsNewIcon color="red" />
                <div className="ml-2">Settings</div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <FontAwesomeIcon icon={faTimesCircle} color="#00733b" />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  )
}

export default SidebarHeader
