import React from 'react'
import { Photo, PostAdd, Assignment } from '@material-ui/icons'
import IntlMessages from '../../../utils/IntlMessages'
export const sidebarNavs = [
  {
    name: <IntlMessages id={'pages.manageCarousel'} />,
    type: 'item',
    icon: <Assignment />,
    link: '/carousel',
    childrenlink: ['/carouselinfo'],
  },
  {
    name: <IntlMessages id={'pages.manageBroadcast'} />,
    type: 'item',
    icon: <Photo />,
    link: '/broadcast',
    childrenlink: ['/broadcastinfo'],
  },
]

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
]

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
]
